<template>
  <div class="episodeCarousel">
    <div class="episodeCarousel-episodes">
      <div class="episodeCarousel-episodes-header" v-if="!changeStyles">
        <p class="episodeCarousel-episodes-header-title">Season</p>
        <div class="episodeCarousel-episodes-header-season-container">
          <p
            v-for="(index, i) in seasonCount"
            :key="i"
            class="episodeCarousel-episodes-header-season-container-count"
            :class="selectedSeason === index ? active : ''"
            @click="getEpisodes(index)"
          >
            {{ showSeason(index) }}
          </p>
        </div>
      </div>

      <div class="episodeCarousel-episodes-header" v-else>
        <!-- <p class="episodeCarousel-episodes-header-title">Season</p> -->
        <select
          v-if="isepisodesAvailable"
          v-model="seasonNum"
          class="episodeCarousel-episodes-header-select"
          @change="getDropEpisodes($event)"
          :class="[localDisplayLang === 'eng' ? 'select-eng' : 'select-ara']"
        >
          <option v-for="(index, i) in seasonCount" :key="i" :value="index">{{ $t("Season") }} {{ index }}</option>
        </select>
        <!-- <i class="arrow down"></i> -->
      </div>

      <div class="episodeCarousel-episodes-component">
        <keep-alive v-if="!purgeContent" v-for="(index, i) in seasonCount" :key="i">
          <contentsListing
            v-if="index == activeSeasonNum"
            :seasonNum="index"
            :showNumbers="showNumbers"
            @episode="episodeItem"
            :changeStyles="changeStyles"
            :content="content"
            :playerInstance="playerInstance"
            :listType="listType"
          ></contentsListing>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";

export default {
  props: {
    content: {
      type: Object,
    },
    changeStyles: {
      type: Boolean,
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      seasonCount: null,
      episodeList: null,
      totalCount: null,
      selectedSeason: null,
      active: "active",
      showNumbers: true,
      seasonNum: 1,
      getTotalCount: false,
      localDisplayLang: null,
      listType: "",
      isepisodesAvailable: false,
      isMpegRequired: false,
      activeSeasonNum: 0,
      purgeContent: false,
    };
  },
  watch: {
    seasonCount(val) {
      if (val) {
        if (val > 0) {
          this.isepisodesAvailable = true;
        }
        this.activeSeasonNum = val;
        this.selectedSeason = val;
        this.seasonNum = val;
        // this.getEpisodes(1);
      }
    },
    content(val) {
      this.purgeContent = true;
      this.seriesid = this.content.objectid;
      this.getSeasonCount();
    },
  },
  computed: {
    fetchCardType() {
      if (screen.width < 576) {
        return {
          cardName: "Episode",
          type: "LANDSCAPE",
          height: "56px",
          width: "100px",
          playButton: { enablePlay: false, height: "40px", width: "40px" },
        };
      } else {
        return {
          cardName: "Episode",
          type: "LANDSCAPE",
          height: "70px",
          playButton: { enablePlay: false, height: "40px", width: "40px" },
        };
      }
    },
  },
  created() {
    this.isMpegRequired = this.checkMpegRequired();
    this.listType = "Episodes";
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.seriesid = this.content.objectid;

    if (this.content.seasoncount) {
      this.seasonCount = this.content.seasoncount;
    } else {
      this.getSeasonCount();
    }

    eventBus.$on("season_listing_response", (response) => {
      console.log("Received season response : ", response);
      if (response.reason) {
        this.episodeList = [];
      } else {
        this.seasonCount = response;
        this.purgeContent = false;
      }
    });

    // eventBus.$on("send-episode-list", response => {
    //     console.log('Received episode list : ', response);
    //   if (!response.reason) {
    //     this.totalCount = response.totalcount;
    //     if (this.getTotalCount) {
    //       this.episodeList = response.data;
    //       this.isepisodesAvailable = true;
    //     } else {
    //       this.getTotalCount = true;
    //       this.getEpisodes();
    //     }
    //   } else {
    //     this.episodeList = [];
    //     this.isepisodesAvailable = false;
    //   }
    // });
  },
  mounted() {
    console.log("Episode carousel mounted");
  },
  beforeDestroy() {
    console.log("Episodes carousel destroyed");
  },
  methods: {
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    getSeasonCount() {
      let payload = {
        objecttype: "SEASON",
        seriesid: this.content.objectid,
      };
      eventBus.$emit("get-seasonnum-listing", payload);
    },
    showSeason(i) {
      if (i < 10) {
        return `0${i}`;
      } else {
        return i;
      }
    },
    getDropEpisodes(val) {
      console.log("get drop episodes called");
      // this.getTotalCount = false;
      // this.totalCount = null;
      // this.episodeList = null;
      if (val.target.value) {
        this.selectedSeason = Number(val.target.value);
        this.activeSeasonNum = Number(val.target.value);
      }
      // let payload = {
      //   objecttype: "CONTENT",
      //   seriesid: this.seriesid,
      //   seasonnum: this.selectedSeason,
      //   pagesize: this.totalCount ? this.totalCount : "",
      //   displaylanguage: this.localDisplayLang
      // };
      // eventBus.$emit("get-episode-list", payload);
    },
    getEpisodes(data) {
      console.log("get episodes called=====");
      this.episodeList = null;
      if (data) {
        this.selectedSeason = data;
      }

      let payload = {
        objecttype: "CONTENT",
        seriesid: this.seriesid,
        seasonnum: this.selectedSeason,
        pagesize: this.totalCount ? this.totalCount : "",
        displaylanguage: this.localDisplayLang,
      };

      if (this.isMpegRequired == true) {
        payload.mpegtspackage = "YES";
      }

      eventBus.$emit("get-episode-list", payload);
    },
    episodeItem(payload) {
      this.$emit("episodeDetail", payload);
    },
  },
  components: {
    contentsListing: () =>
      import(/* webpackChunkName: "contentsListing" */ "@/components/Templates/contentsListing.vue"),
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./episodesCarousel.scss"
</style>
